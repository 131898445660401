* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', SimSun, sans-serif;
}

:root {
  --foreground: #000;
  --background: #fff;
  --primaryColor: #191a1b;
  --textColorSecondary: rgba(0, 0, 0, 0.45);
}

body {
  min-width: 300px;
  background: #fff;
}

html {
  font-size: 16px;
}

@media (max-width: 768px) {
  html {
    font-size: 14px; /* 或其他适合的大屏幕字体大小 */
  }
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none !important; /* 阻止 iOS 根据用户设置调整字体大小 */
  text-size-adjust: none !important;
  overscroll-behavior-y: none;
}

button:focus {
  outline: none;
}

*::before,
::after {
  box-sizing: border-box;
}

.text-fill-transparent {
  -webkit-text-fill-color: transparent !important;
}

.line-clamp {
  overflow: hidden;
  /* stylelint-disable-next-line */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.select-card-modal .ant-modal-content {
  background-color: #ff00 !important;
  opacity: 0.9;
  box-shadow: none !important;
}

.select-card-modal #selcet-wild-card-modal-body {
  background-color: #7b82f6 !important;
  opacity: 0.9;
  padding: 48px;
  border-radius: 12px;
}

.ant-message .ant-message-notice-wrapper .custom-message .ant-message-notice-content {
  display: inline-block !important;
  padding: 12px 20px !important;
  background: rgba(255, 255, 255, 0.9) !important;
  border-radius: 16px !important;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  pointer-events: all !important;
}

.collapse-body .ant-collapse-content-box {
  background-color: #fff;
}

.divider-bg .ant-divider-with-text::before,
.ant-divider-with-text::after {
  background-color: white;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.brand-logo img {
  height: 60px;
  max-width: 100%;
  transition: all 0.4s ease;
}

.container::before,
.container::after,
.container-fluid::before,
.container-fluid::after,
.row::before,
.row::after {
  content: ' ';
  display: table;
}

.container::after,
.container-fluid::after,
.row::after {
  clear: both;
}

.disable-drag {
  -webkit-user-drag: none;
}

svg {
  vertical-align: baseline !important;
}

div > * {
  outline: none !important;
}

.ant-card-actions {
  border-radius: 0.75rem !important;
  background-color: transparent !important;
}

h1,
h2,
h3 {
  font-weight: bold !important;
}

h2 {
  font-size: 30px !important;
}

h3 {
  font-size: 20px !important;
}

input.input {
  color: #a1a1a1;
  padding: 0 6px;
}

input.input:focus {
  color: #1a1a1a;
  outline: none !important;
}

.wechat-tip {
  max-width: 100vw;
  margin: 0;
  height: 100vh;
}

@media (max-width: 1199px) {
  .footer-headline {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 40px;
  }
}

@media (min-width: 991px) {
  .footer-headline {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 40px;
  }
}

.grid-table .ant-table-cell {
  padding: 2px !important;
}

.grid-table * {
  border-radius: 0 !important;
}

#score-details-table .ant-table-cell {
  border-color: #0004 !important;
}

.name-justify div {
  width: 60px;
  text-align: justify;
  text-align-last: justify;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.team-justify div {
  width: 60px;
  text-align: justify;
  display: inline-block;
}

.tiny-team-justify div {
  width: 40px;
  text-align: justify;
  display: inline-block;
}

#roundCard:hover #info {
  display: unset !important;
}

#roundCard:hover {
  background-color: #eeee !important;
  background-image: unset !important;
  box-shadow: #191a1b;
}

#partnerDiv:hover #partnerPic {
  opacity: 0.2;
}

#partnerDiv:hover #partnerLink {
  opacity: 1 !important;
}

.slideshow {
  position: relative;
}

.slideshow img {
  transition: opacity 1s ease-in-out;
}

.slideshow img.hide {
  opacity: 0;
}

.pulsating-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px; /* 可根据需要调整尺寸 */
  height: 120px;
  margin-left: -60px; /* 保证居中 */
  margin-top: -60px;
  border: 8px solid #007bff; /* 蓝色边框 */
  border-radius: 50%;
  background: transparent;
  opacity: 0.6;
  animation: pulsate 2s infinite ease-in-out; /* 改长动画时长，减少跳跃感 */
}

/* 定义优化后的动画效果 */
@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
